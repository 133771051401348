/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('#nav').tinyNav({
          header: 'Sélectionner',
        });
        // Vignette biseaute
        $('.vignette img').clipPath([[11,0],[100,0],[100,100],[0,100],[0,31]], {isPercentage:true});
        // Vignette carousel
        $('[id^=carousel-selector-]').clipPath([[13,0],[100,0],[100,100],[0,100],[0,31]], {isPercentage:true});
        // Image hexa du carousel acteurs
        $('.hexa').clipPath([[25,0],[75,0],[100,50],[75,100],[25,100],[0,50]], {isPercentage:true});
        // Vignette acteur
        $('img.acteur').clipPath([[13,0],[100,0],[100,100],[0,100],[0,31]], {isPercentage:true});
        // Vignette projet
        $('#projets .vignette img').clipPath([[9,0],[100,0],[100,100],[0,100],[0,31]], {isPercentage:true});

        // Form Mailchimp
        $('form#newsletterForm').submit(function(e) {
          e.preventDefault();
          var email = '';
          if($('#email').val() === '') {
            email = $('#email2').val();
          } else {
            email = $('#email').val();
          }
          $.post(ajaxVars.ajaxurl, { ajax_nonce : ajaxVars.ajax_nonce, action : 'add_to_mailchimp_list', email: email }, function(response) {
            response = JSON.parse(response);
            $('.modal-body').html(response.message);
            $('#newsletterModal').modal();
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        // Carousel
        $('#carouselHome').carousel({
            interval: 8000
        });

        $('#carousel-text-content').html($('#slide-content-0').html());

        //Handles the carousel thumbnails
       $('[id^=carousel-selector-]').click( function(){
          var id = parseInt(this.id.substr(this.id.lastIndexOf("-") + 1));
          $('#carouselHome').carousel(id);
        });


        // When the carousel slides, auto update the text
        $('#carouselHome').on('slid.bs.carousel', function (e) {
          var id = $('.item.active').data('slide-number');
          $('#carousel-text-content').html($('#slide-content-'+id).html());
        });

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
